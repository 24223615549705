export const InsuranceTypes = {
  MONTHLY: 'Monthly',
  ANNUAL: 'Annual',
  HOURLY: 'Hourly',
  WEEKLY: 'Weekly',
}

export const InsuranceBillingPeriod = {
  WEEK: 'Week',
  MONTH: 'Month',
  DAY: 'Day',
  YEAR: 'Year',
}
