import ApiService, { HTTPMethod } from './api.core'
import { FirebaseService, FirebaseConfigKey } from '../services/FirebaseService'

class DiscountsApi {
  async useRedemptionCode(redemptionCode) {
    if (!redemptionCode) return

    return ApiService.fetch('users/me/redeem_code', HTTPMethod.POST, {
      redeem_code: redemptionCode,
    })
  }

  async uploadFlightLogs(email, files, onSuccess, onError, onProgress) {
    let form = new FormData()
    form.append('email', email)
    return ApiService.uploadFileXHR(
      FirebaseService.getValue(FirebaseConfigKey.FLIGHT_LOGS_URL),
      form,
      files,
      onSuccess,
      onError,
      onProgress
    )
  }
}

export default new DiscountsApi()
