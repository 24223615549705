import { Resizer } from '../utilities/Resizer'
import ApiService, { HTTPMethod } from './api.core'

const uuidv4 = require('uuid/v4')

class OdometerApi {
  uploadOdometer(dataUri, prediction, token, insuranceId) {
    var form = new FormData()

    form.append('file', Resizer.b64toBlob(dataUri), uuidv4())
    form.append('value', prediction)
    form.append('token', token)

    return ApiService.fetch(
      `insurances/${insuranceId}/odometer`,
      HTTPMethod.POST,
      form,
      false
    )
  }

  async getOdometerPredicition(dataUri) {
    try {
      let response = await fetch(
        `${process.env.REACT_APP_AI_SERVER_URL}/getOdometerPrediction`,
        {
          method: HTTPMethod.POST,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            image: dataUri.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''),
          }),
        }
      )
      let resp = await response.json()
      resp.ok = true
      return resp
    } catch (e) {
      return {
        ok: false,
      }
    }
  }
}

export default new OdometerApi()
