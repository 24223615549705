import React from 'react'
import FlexView from 'react-flexview/lib'
import CheckIcon from '@material-ui/icons/Check'
import { Grow, IconButton } from '@material-ui/core'

export const OdometerFlowCongratz = () => {
  const [show, setShow] = React.useState(false)

  React.useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, 200)
  }, [])

  return (
    <FlexView column className="priview-background">
      <FlexView className="priview-background2" hAlignContent={'center'}>
        <FlexView column className="preview-content">
          <Grow
            in={show}
            style={{ transformOrigin: '0 0 0' }}
            {...(show ? { timeout: 1000 } : {})}>
            <FlexView
              hAlignContent={'center'}
              column
              vAlignContent={'center'}
              className="preview-title-top">
              <div className="text-promotion textcenter bold white margin-bottom-basic">
                HORRAY!
              </div>
              <IconButton className="img-ok-btn margin-bottom-basic">
                <CheckIcon className="white x-large-icon clickable" />
              </IconButton>
              <div className="text-title white textcenter margin-bottom-basic">
                Your Odometer reading was sent successfully.
              </div>
              <div className="text-title white textcenter margin-bottom-basic">
                A billing statement will be emailed in a few days.
              </div>
              <br />
              <br />
              <img
                className="logo-fit"
                src={process.env.PUBLIC_URL + '/assets/voom_white.png'}
              />
            </FlexView>
          </Grow>
        </FlexView>
      </FlexView>
    </FlexView>
  )
}
