import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import odometerApi from '../../api/odometer.api'
import { awaitWithLoading } from '../../services/utils'
import { CameraWithTemplate } from './CameraWithTemplate'
import { ImagePreview } from './ImagePreview'
import queryString from 'query-string'
import {
  EventEmitterService,
  EventKey,
} from '../../services/EventEmitterService'

export const TakePictureFlow = () => {
  const [dataUri, setDataUri] = React.useState('')
  const history = useHistory()
  const location = useLocation()
  const params = queryString.parse(location.search)

  const handleTakePhotoAnimationDone = (dataUri) => {
    setDataUri(dataUri)
  }

  const onAccept = async () => {
    if (!dataUri) return

    EventEmitterService.dispatch(EventKey.ShowLoader)
    let resp = await odometerApi.getOdometerPredicition(dataUri)
    console.log(resp)

    let predicition = null
    if (resp.ok && resp.prediction.length > 0) {
      predicition = resp.prediction[0]
    }

    let res = await odometerApi.uploadOdometer(
      dataUri,
      predicition,
      params.token,
      params.InsuranceId
    )
    EventEmitterService.dispatch(EventKey.DismissLoader)

    history.push({
      pathname: '/odometer/congrats',
      search: location.search,
    })
  }

  const onCancel = () => setDataUri('')

  return (
    <>
      {dataUri ? (
        <ImagePreview
          dataUri={dataUri}
          onAccept={onAccept}
          onCancel={onCancel}
        />
      ) : (
        <CameraWithTemplate
          handleTakePhotoAnimationDone={handleTakePhotoAnimationDone}
        />
      )}
    </>
  )
}
