import React from 'react'
import './CircleButton.scss'
const classNames = require('classnames')

export const CircleButton = ({ showGreen, onClick, isClicked }) => {
  return (
    <>
      <div className="circle" onClick={onClick} />
      {showGreen && <div className="green-circle" />}
      {!showGreen && <div className="loader" />}
    </>
  )
}
