import clickSound from './click-sound.base64.json'

export function getShowHideStyle(isDisplay) {
  const displayStyle = isDisplay
    ? { display: 'inline-block' }
    : { display: 'none' }

  return displayStyle
}

export function _getMirrorCameraStyle(imageMirror) {
  const mirrorDisplayStyle = imageMirror
    ? { transform: 'rotateY(180deg)' }
    : { transform: 'none' }

  return mirrorDisplayStyle
}

export function getVideoStyles(isDisplay, imageMirror) {
  return {
    ..._getMirrorCameraStyle(imageMirror),
    ...getShowHideStyle(isDisplay),
  }
}

export function playClickAudio() {
  let audio = new Audio('data:audio/mp3;base64,' + clickSound.base64)
  audio.play()
}

export function printCameraInfo(info) {
  console.info('react-html5-camera-photo info:', info)
}

export function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}

export function blobToDataURL(blob, callback) {
  var a = new FileReader()
  a.onload = function (e) {
    callback(e.target.result)
  }
  a.readAsDataURL(blob)
}
