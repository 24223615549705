export class Resizer {
  static resizeAndRotateImage(
    image,
    idealHeight,
    idealWidth,
    compressFormat = 'jpeg',
    quality = 100,
    rotation = 0
  ) {
    var qualityDecimal = quality / 100
    var canvas = document.createElement('canvas')

    canvas.width = idealWidth
    canvas.height = idealHeight

    var ctx = canvas.getContext('2d')

    if (rotation) {
      ctx.rotate((rotation * Math.PI) / 180)
      if (rotation === 90) {
        ctx.translate(0, -canvas.width)
      } else if (rotation === 180) {
        ctx.translate(-canvas.width, -canvas.height)
      } else if (rotation === 270) {
        ctx.translate(-canvas.height, 0)
      } else if (rotation === 0 || rotation === 360) {
        ctx.translate(0, 0)
      }
    }
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height)

    return canvas.toDataURL(`image/${compressFormat}`, qualityDecimal)
  }

  static b64toBlob(b64Data, contentType = 'image/jpeg') {
    var sliceSize = 512

    var byteCharacters = atob(
      b64Data.toString().replace(/^data:image\/(png|jpeg|jpg);base64,/, '')
    )
    var byteArrays = []

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize)

      var byteNumbers = new Array(slice.length)
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      var byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    var blob = new Blob(byteArrays, { type: contentType })
    return blob
  }

  static createResizedImage(
    file,
    maxWidth,
    maxHeight,
    compressFormat,
    quality,
    rotation,
    outputType = 'base64'
  ) {
    return new Promise((resolve) => {
      var blob = null
      const reader = new FileReader()
      if (file) {
        reader.readAsDataURL(file)
        reader.onload = () => {
          var image = new Image()
          image.src = reader.result
          image.onload = function () {
            var resizedDataUrl = Resizer.resizeAndRotateImage(
              image,
              maxWidth,
              maxHeight,
              compressFormat,
              quality,
              rotation
            )
            blob = Resizer.b64toBlob(resizedDataUrl, `image/${compressFormat}`)
            outputType === 'blob' ? resolve(blob) : resolve(resizedDataUrl)
          }
        }
        reader.onerror = (error) => {
          resolve(error)
        }
      } else {
        resolve('File Not Found')
      }
    })
  }
}
export default {
  imageFileResizer: (
    file,
    maxWidth,
    maxHeight,
    compressFormat,
    quality,
    rotation
  ) => {
    return Resizer.createResizedImage(
      file,
      maxWidth,
      maxHeight,
      compressFormat,
      quality,
      rotation
    )
  },
}
