import { FACING_MODES } from 'jslib-html5-camera-photo'
import React, { useContext } from 'react'
import FlexView from 'react-flexview/lib'
import { SessionContextStore } from '../../context/SessionContext'
// import Camera , {FACING_MODES} from 'react-html5-camera-photo';
// import 'react-html5-camera-photo/build/css/index.css';
import './CameraWithTemplate.scss'
import NewCamera from './NewCamera'
const classnames = require('classnames')

export const CameraWithTemplate = ({
  handleTakePhotoAnimationDone,
  ...props
}) => {
  const sessionContext = useContext(SessionContextStore)
  return (
    <>
      {/* <div id="rectangle"></div> */}
      <FlexView
        hAlignContent={'center'}
        className="camera_overlay"
        vAlignContent={'bottom'}>
        <div className="bolder white textcenter text-title">
          Hey{' '}
          {sessionContext.user.firstName
            ? `${sessionContext.user.firstName}`
            : 'VOOMer'}
          ,
        </div>
        <div
          className={classnames('white textcenter subtextoverlay text-body')}>
          Snap a photo of your Odometer reading
        </div>
      </FlexView>
      <NewCamera
        idealFacingMode={FACING_MODES.ENVIRONMENT}
        onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
      />
    </>
  )
}
