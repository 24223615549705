import ApiService, { HTTPMethod } from './api.core'

export class AnalyticsApi {
  async addUtmLog(type, cookie_string) {
    const CookieJson = {}
    if (cookie_string != null) {
      const url = new URL(cookie_string)
      CookieJson['URL'] = url
      url.searchParams.forEach((value, key) => {
        CookieJson[key] = value
      })
    }

    return await ApiService.fetch('general/AddUtmLog', HTTPMethod.POST, {
      type,
      payload: CookieJson,
    })
  }
  async utmEvent() {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    await ApiService.fetch('analytics/utm_event', HTTPMethod.POST, {
      type: 'UtmUserCreated',
      payload: {
        utm_source: params?.utm_source,
        utm_medium: params?.utm_medium,
        utm_campaign: params?.utm_campaign,
      },
    })
  }
}
export default new AnalyticsApi()
