import React from 'react'
import './InsurerFooter.css'
import { isMobile, BrowserView } from 'react-device-detect'
import { useLocation } from 'react-router-dom'
import { Experiment, Variant } from 'react-optimize'

export const InsurerFooter = () => {
  const location = useLocation()

  if (
    location.pathname.startsWith('/odometer') ||
    location.pathname == '/login' ||
    location.pathname == '/brokerLogin' ||
    location.pathname == '/logout'
  )
    return <div />

  return (
    <>
      <br />
      <div className="text-tiny gray footer-text">
        {/* <div>
                Insurance is provided by SkyWatch Insurance 
                Services, Inc, an insurance agency licensed to 
                sell property-casualty insurance products and receives compensation from insurance 
            </div>
            <div className="margin-top-3">
                companies for such sales. Coverage is subject to the actual 
                policy terms and conditions, please also 
                refer to our <a rel="noopener noreferrer" 
                    target="_blank"
                    href="https://www.skywatch.ai/terms-conditions" 
                    className="blue underline clickable">Terms and Conditions</a> and <a rel="noopener noreferrer"
                        target="_blank"
                        href="https://www.skywatch.ai/privacy-policy"
                        className="blue underline clickable">Privacy Policy</a>.
            </div> */}
        {isMobile && location.pathname.startsWith('/insurance') && (
          <div style={{ height: 80 }}></div>
        )}
      </div>
    </>
  )
}
